/* NUEVO DISEÑO */
.bg-image {
    /* Image used */
    background-image: url("../../../../../assets/img/fondo-login.png");

    /* Full height */
    height: 100vh;
    
    /* Centering and Scaling */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-content {
    background-color: rgb(12,53,41); /* Fallback color */
    background-color: rgba(12, 53, 41, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
}

input.login-field{
    background-color: red;
}