@import "../../../../../style/configs/color.css";

.alerta-text {
    border: 1px solid #0c3529;
    padding: 5px 15px;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 80%;
    color: #606060;
}

.alerta-danger-text {
    border: 1px solid #ad1717;
    padding:       5px 15px;
    border-radius: 1rem;
    font-weight: bold;
    font-size:     80%;
    color: #606060;
}

.blink-danger {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.alerta-container {
    display:       flex;
    align-items:   center;
    margin-bottom: 2rem;
}

.alerta-danger {
    display:       flex;
    align-items:   center;
    /* margin-bottom: 2rem; */
}

.logo-changepass {
    height: 8rem;
}

/* isHover true blink-danger */
.containerAlert {
    position: relative;
}
.showText {
    position: absolute;
    top: -10px;
    background-color: white;
    right: 35px;
    /* no romper texto */
    white-space: nowrap;
    /* ocupar todo el ancho que sea necesario */ 
    width: fit-content;
    
}
.containerAlert .showText {
    display: none;
}

.containerAlert:hover .showText {
    display: block;
}
