@import "../configs/color.css";

/* ---------------- ESTILOS GLOBALES DE IMPRESION ---------------------*/
@page {
    margin: 30px;
    size: portrait;
}
@media screen {
    .no-mostrar {
        display: none !important;
    }
}
@media print {
    @page {
        margin: 30px;
        size: portrait
    }

    body,
    html,
    html body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        min-width: 0 !important;
        max-height: auto !important;
        -webkit-print-color-adjust: exact;
        background-color: #FFFFFF !important;
        -webkit-print-color-adjust: exact;
    }
    .hoja-carta{
        width: 21.6cm;
        height: 27.9cm;
        background-color: red;
    }
    .media-carta-arriba{
        max-width: 21.6cm;
        width: 21.6cm;
        max-height: 15.95cm;
        height: 15.95cm;
    }

    .media-carta-abajo{
        max-width: 21.6cm;
        width: 21.6cm;
        max-height: 11.95cm;
        height: 11.95cm;
    }

    .columna-finca{
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 33.333333% !important;
        flex: 0 0 33.333333% !important;
        max-width: 33.333333% !important;
    }

    .columna-finca-contactos{
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .dont-print {
        display: none !important
    }
    #PRINT-ROOT-CONTAINER {
        font-size: 11px;
        display: block !important;
    }
    .form-control[readonly] {
        background-color: #FFFFFF !important;
    }

    .columna-izquierda{
        font-size: 8.5px !important;
    }
    .columna-derecha{
        font-size: 8.5px !important;
        font-weight: bold;
    }
    .total-gris {
        font-size: 9.5px !important;
        font-weight: bold;
    }
    .contenido-impresion h6{
        font-size: 14px !important;
    }
    .react-bs-table table td, .react-bs-table table th {
        /* overflow: hidden; */
        white-space: unset !important;
        text-overflow: unset;
    }
    .table td, .table th {
        border: none !important;
    }

    .impresion-horizontal{
        @page {
            margin: 30px;
            size: landscape
        }
    }
}
#PRINT-ROOT-CONTAINER {
    display: none;
}

.espacio-firma {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    width: 100%;
}

.total tbody{
    border-left: 0 solid #ebebeb;
    border-bottom: 0 solid #ebebeb;
    border-right: 0 solid #ebebeb;
    border-radius: 0 0 1rem 1rem;
    color: #454545;
}

.total .react-bs-container-body {
    border-left: 0 solid #ebebeb;
    border-bottom: 0 solid #ebebeb;
    border-right: 0 solid #ebebeb;
    border-radius: 0 0 1rem 1rem;
}

.total-final tbody{
    border-left: 0 solid #ebebeb;
    border-bottom: 0 solid #ebebeb;
    border-right: 0 solid #ebebeb;
    border-radius: 0 0 1rem 1rem;
    color: #454545;
}
.fondo-blanco .table th{
    background: white;
    font-size: 0.9rem;
}

.total-final-trading .react-bs-container-header{
    border-left: 0 solid #ebebeb;
    border-bottom: 0 solid #ebebeb;
    border-right: 0 solid #ebebeb;
    border-radius: 0;
    color: #454545;
}
.total-final-trading th:first-child{
    border-top-left-radius: 0;
}

.total-final-trading th:last-child {
    border-top-right-radius: 0;
}

.inicio-tabla .react-bs-container-body {
    border-radius: 0 0 0 0 !important;
    font-weight: bold !important;
}

.total .react-bs-container-header {
    border-radius: 0 0 0 0 !important;
}

.total th:first-child {
    border-top-left-radius: 0rem !important;
    /* border-top-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; */
}

.total th:last-child {
    border-top-right-radius: 0 !important;
}

.descuentos-table .react-bs-container-header {
    border-radius: 0 !important;
}

.descuentos-table th:first-child {
    border-radius: 0 !important;
}

.descuentos-table th:last-child {
    border-radius: 0 !important;
}

.descuentos-table .table th {
    border-bottom: 0 !important;
}

.total-final .react-bs-container-header {
    border-radius: 0 0 1rem 1rem !important;
}

.total-final th:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 1rem !important;
}

.total-final th:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 1rem !important;
}

.total-final .table th {
    border-bottom: 0 !important;
    /* background: white !important; */
    /* color: #035192; */
}

.total-gris {
    font-size: 15px;
    color: #454545 !important;
}
.gris-total{
    color: #454545 !important;
}
