.main-navbar .navbar {
    height: 3.75rem
}
.main-navbar .navbar > * {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.main-navbar .navbar .nav-link {
    min-width: 3.75rem
}

.main-navbar .navbar .notifications {
    position: relative
}

.main-navbar .navbar .notifications .badge {
    position: absolute;
    padding: .25rem .375rem;
    font-size: .5rem;
    left: 50%;
    top: 50%
}

.main-navbar .navbar .notifications .dropdown-menu {
    padding: 0;
    min-width: 25rem;
    border-right: 0;
    left: auto
}

.main-navbar .navbar .notifications .dropdown-menu .dropdown-item {
    white-space: normal;
    display: flex;
    flex-flow: row;
    padding-top: .625rem;
    padding-bottom: .625rem;
    border-bottom: 1px solid #e1e5eb
}

.main-navbar .navbar .notifications .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon-wrapper {
    display: flex;
    /*padding: 0 5px*/
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon {
    background-color: #f5f6f8;
    box-shadow: 0 0 0 1px #fff, inset 0 0 3px rgba(0, 0, 0, .2);
    width: 2.1875rem;
    height: 2.1875rem;
    line-height: 0;
    display: block;
    text-align: center;
    margin: auto;
    /* border-radius: 50% */
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon i {
    color: #818ea3;
    line-height: 2.0625rem;
    font-size: 1.0625rem;
    margin: 0
}

.main-navbar .navbar .notifications .dropdown-menu .notification__content {
    padding: 0 .625rem
}

.main-navbar .navbar .notifications .dropdown-menu .notification__content p {
    margin: 0;
    line-height: 1.5;
    font-size: .75rem
}

.main-navbar .navbar .notifications .dropdown-menu .notification__category {
    font-size: .5625rem;
    color: #818ea3;
    letter-spacing: .0938rem;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 500
}

.main-navbar .navbar .notifications .dropdown-menu .notification__all {
    display: block;
    font-weight: 500;
    font-size: 11px;
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.main-navbar .navbar .user-avatar {
    width: 1.5rem;
    height: 1.5rem;
}

.main-navbar .navbar .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
    width: 80%;
    border-top: none;
    /* border-radius: 1.5rem; */
    overflow: hidden;
    min-width: 8.5rem;
}

.main-navbar .navbar .nav-link-icon i {
    line-height: 2.5rem
}


/*Otros estilos*/
.navbar {
    position: relative;
    padding: .5rem 1rem
}

.navbar, .navbar > .container, .navbar > .container-fluid {
    display: flex;
    /*flex-wrap: wrap;*/
    /*align-items: center;*/
    /*justify-content: space-between*/
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none
}

/*.navbar-nav {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding-left: 0;*/
/*    margin-bottom: 0;*/
/*    list-style: none*/
/*}*/

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: initial;
    border: 1px solid transparent;
    /* border-radius: .25rem */
}

.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%
}

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, .5);
    border-color: hsla(0, 0%, 100%, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
    color: #fff
}


.dropdown-menu {
    padding: 0 0 !important;
    /* border-radius: 1.5rem; */
}

.dropdown-item {
    font-weight: 300;
    color: #212529;
    font-size: .9375rem;
    -webkit-transition: background-color .25s cubic-bezier(.27, .01, .38, 1.06), color .25s cubic-bezier(.27, .01, .38, 1.06);
    transition: background-color .25s cubic-bezier(.27, .01, .38, 1.06), color .25s cubic-bezier(.27, .01, .38, 1.06);
    a{
        color: $verde-secundario;
        text-decoration: none;
    }
}

.dropdown-item.active, .dropdown-item:active {
    color: white;
    background-color: #cdded5;
    a{
        color: white;
    }
}

.dropdown-item:focus, .dropdown-item:hover {
    color: white;
    background-color: #cdded5;
    a{
        color: white;
    }
}
