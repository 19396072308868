label {
    font-weight: bold;
    padding-left: 15px !important;

}

.label-login {
    padding-left: 0 !important;
}

@media (max-width: $md) {
    h2 {
        font-size: 1.2rem;
    }
}

.titulo {
    color: #0c3529 !important;
    font-weight: bold;
}

.subtitulo{
    color: #035b2f !important;
}

.tachado {
    text-decoration: line-through;
}
.tachado-touch {
    text-decoration: line-through;
    cursor: pointer;
}

/*POPUP*/
.popup-content{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    text-wrap: avoid;
    border-radius: 0.5rem;
    white-space: initial;
    overflow: hidden;
    .popup-title{
        background: $gris-claro;
        color: $azul;
        text-align: center;
        font-weight: bold;
        padding: 5px;
    }
    .popup-text{
        color: $gris;
        padding: 5px;
        word-break: break-all;
    }
}

.bold{
    font-weight: bold;
}

.text-md{
    font-size: 1.5rem;
}

.text-lgs{
    font-size: 1.8rem;
}

.text-lg{
    font-size: 2rem;
}

.text-xs {
    font-size: 0.7rem;
}

.text-sm {
    font-size: 1rem;
}

.text-qts {
    font-size: 1.3rem;
}

/* Es el CSS para la alerta de eliminar, para el texto que aparece en la parte media. */
.text-azul-alerta{
    color: #035192;
    font-weight: 100;
}

#swal2-content{
    border: 1px solid #0c3529 !important;
    padding: 5px 15px !important;
    border-radius: 1rem !important;
    font-weight: bold !important;
    font-size: 70% !important;
    width: 50% !important;
}
