$color-title: #721c24;
$color-navbar: #006FBD;

$primary: #007bff;

$verde: #8ec640;
$verde-nuevo: #006f43;
$verde-primario: #0c3529;
$verde-secundario: #035b2f;
$azul-secundario: #0c3856;
$azul: #215273;
$rojo: #9b1c22;
$cian: #55c4d4;
$gris: #454545;
$gris-medium: #6e6e6e;
/*Gris claro*/
$gris-claro: #ebebeb;
$negro: #000;

.verde{
    color: $verde;
}
.verde-nuevo{
    color: $verde-nuevo;
}
.verde-primario {
    color: $verde-primario;
}
.verde-secundario {
    color: $verde-secundario;
}
.azul-secundario {
    color: $azul-secundario;
}
.azul{
    color: $azul;
}
.rojo{
    color: $rojo;
}
.cian{
    color: $cian;
}
.gris{
    color: $gris;
}
.gris-claro{
    color: $gris-claro;
}
.negro{
    color: $negro;
}
