.CircularProgressbar {
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #00A651;
  /*stroke-linecap: round;*/
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #00A651;
  font-size: 16px;
  /*dominant-baseline: middle;*/
  text-anchor: middle;
  font-weight: bold;
}
.circular-extra-text {
  position: absolute;
  width: 80%;
  top: 3px;
  left: 29px;
  color: $verde-secundario !important;
}
.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}
.CircularProgressbarContainer {
  position: relative;
  width: 100px;
  height: 100px;
}
.CircularProgressbarContainer-sm {
    position: relative;
  width: 25px;
  height: 25px;
}
